<template>
  <v-container fluid class="py-0">
    <v-form ref="form">
      <div v-for="(key, k_i) of keysMapping" :key="k_i">
        <v-list-item>  
          <v-list-item-content>
            <v-list-item-title v-text="$data[key].title"></v-list-item-title>
          </v-list-item-content>

          <v-list-item-avatar>
            <v-switch v-model="$data[key].shared" :disabled="$data[key].disabled"></v-switch>
          </v-list-item-avatar>
        </v-list-item>
        <v-divider></v-divider>
      </div>

      <div class="py-4">
        <v-btn @click="updateDemographics" color="primary">{{ $t('update') }}</v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
import { DataService } from "./../../services/DataService";

const dataService = new DataService();

export default {
  data() {
    return {
      demographics: this.$store.state.userProfile.demographics,
      keysMapping: {
        "Gender": "gender",
        "Date Of Birth": "dob",
        "Marital Status": "maritalStatus",
        "Country": "country"
      },
      gender: {
        title: this.$t('gender'),
        required_message: `${this.$t("gender_required")} ${this.$t('required')}`,
        answer: null,
        shared: true,
        questionType: "dropdown",
        options: [
          {
            key: "Male",
            name: this.$t("male")
          },
          {
            key: "Female",
            name: this.$t("female")
          }
        ],
      },
      maritalStatus: {
        title: this.$t('marital_status'),
        required_message: `${this.$t("marital_status")} ${this.$t("required")}`,
        answer: null,
        shared: true,
        questionType: "dropdown",
        options: [
          {
            key: "Single",
            name: this.$t("single")
          },
          {
            key: "Married",
            name: this.$t("married")
          },
          {
            key: "Divorced",
            name: this.$t("divorced")
          }
        ]
      },
      dob: {
        title: this.$t('date_of_birth'),
        required_message: `${this.$t("date_of_birth")} ${this.$t("required")}`,
        answer: null,
        shared: true,
        questionType: "date",
        disabled: true,
        dateMenu: false,
        tempAnswer: false,
      },
      country: {
        title: this.$t('country'),
        required_message: `${this.$t('country')} ${this.$t('required')}`,
        answer: null,
        shared: true,
        disabled: true,
        options: []
      }
    }
  },
  mounted() {
    this.$store.state.loader = true
    
    dataService.getConfig("countries").then(data => {
      this.country.options = data
      this.$store.state.loader = false
    })

    for (let d of this.demographics) {
      let localObject = this[this.keysMapping[d.title]]

      localObject.answer = d.answer
      localObject.shared = d.shared
    }
  },
  methods: {
    parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    updateDemographics() {
      if (this.$refs.form.validate()) {
        this.$store.state.loader = true

        let toUploadDemographics = []

        for(let key of Object.keys(this.keysMapping)) {
          let localObject = this[this.keysMapping[key]]

          toUploadDemographics.push({
            title: key,
            answer: localObject.shared == true ? localObject.answer : null,
            shared: localObject.shared
          })
        }

        dataService.updateMobileUser(this.$store.state.token, {
          userId: this.$store.state.userProfile._id,
          demographics: toUploadDemographics
        }).then(() => {
          this.$store.state.snackBarText = this.$t('successfully_updated')
          this.$store.state.showSnackBar = true
          this.$store.state.loader = false
        })
      }
    }
  },
};
</script>